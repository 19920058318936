import RelationToOneField from '@/shared/fields/relation-to-one-field';

export class FileStatusField {
    static relationToOne(name, label, options, filterParams) {
        return new RelationToOneField(
            name,
            label,
            (params) => {
                return new Promise((resolve) => {
                    if (!params) {
                        resolve([]);
                    }
                    resolve(params.variables.find( param => param.name === name).values);
                });
            },
            (action) => {
                if (!action) {
                    return null;
                }
                return {
                    value: action,
                    text: action,
                };
            },
            options,
            filterParams,
        );
    }
}
