import RelationToOneField from '@/shared/fields/relation-to-one-field';
import { ProcessService} from '@/modules/process-editor/process-editor-service';

export class ActionTypeField {
    static relationToOne(name, label, options, filterParams) {
        return new RelationToOneField(
            name,
            label,
            () => {
                return new Promise((resolve) => {
                    ProcessService.actionsType()
                    .then((data) => {
                        resolve(data);
                    })
                })
            },
            (action) => {
                if (!action) {
                    return null;
                }
                return {
                    value: action.value,
                    text: action.text,
                };
            },
            options,
            filterParams,
        );
    }
}
