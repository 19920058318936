<template>
    <st-page
        :title="title"
        layout="tab"
        :backLink="backLink"
    >
        <loader v-if="isLoading"/>
        <b-tabs class="st-tabs" justified lazy v-model="activeTab">
            <b-tab v-for="tab in tabs" :key="tab.index" :disabled="tab.disabled">
                <template #title>
                    <div class="d-flex justify-content-center w-100">
                        <i :class="['fas', tab.icon]"></i>
                        <span class="text-uppercase ml-4">{{ tab.titleKey }}</span>
                    </div>
                </template>
                <component
                    v-if="!isLoading"
                    :is="tab.componentType"
                    :processId="id"
                    @nextStep="nextStep"
                    @updateProcess="updateProcess"
                >
                </component>
            </b-tab>
        </b-tabs>
    </st-page>
</template>

<script>
import ProcessGeneral from "../components/tabs/ProcessGeneral";
import ProcessStages from "../components/tabs/ProcessStages";
import {mapActions, mapGetters, mapMutations} from 'vuex';
export default {
    name: 'ProcessForm',
    components: {
        ProcessGeneral,
        ProcessStages,
    },
    props: {
        id: String,
    },
    data() {
        return {
            activeTab: 0,
            tabs: [
                {
                    index: 0,
                    name: 'process-general',
                    titleKey: this.$t('PROCESS_EDITOR.TABS.GENERAL'),
                    icon:   'fa-edit',
                    componentType: 'ProcessGeneral',
                    disabled: false,
                },
                {
                    index: 1,
                    name: 'process-stages',
                    icon: 'fa-list',
                    titleKey: this.$t('PROCESS_EDITOR.TABS.STEPS'),
                    componentType: 'ProcessStages',
                    disabled: true,
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            record: 'processEditor/processForm/record',
            loading: 'shared/loading',
            processEditorPermissions: 'processEditor/processEditorPermissions',
        }),
        backLink() {
            return  { name: 'process-editor' } ;
        },
        title() {
            if (!this.id) return `${this.$t('PROCESS_EDITOR.GENERAL.TITLE')}`;
            return this.record?.display_name;
        },
        isLoading() {
            return this.loading['processEditor/find'];
        },
    },
    async created() {
        if (this.id) {
            this.tabs[1].disabled = !this.id || !this.processEditorPermissions.hasPermissionToViewSteps;
            await this.doFind(this.id);
        } else {
            await this.doClear();
        }
    },
    methods: {
        ...mapActions({
            doFind: 'processEditor/processForm/find',
            doClear: 'processEditor/processForm/clear',
        }),
        ...mapMutations({
            setSelected: 'processEditor/processForm/setSelected',
        }),
        nextStep() {
            let currentTab = this.activeTab;
            currentTab++;
            this.tabs[currentTab].disabled = false;
            this.$nextTick(() => {
                this.activeTab = currentTab;
            })
        },
        updateProcess(process) {
            this.setSelected({...this.record, display_name: process.display_name});
        }
    },
}
</script>
