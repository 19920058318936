<template>
    <st-filters-pagination
        :header="$t('PROCESS_EDITOR.STEPS.HEADER_LIST')"
        :perPage=100
        :total="total"
        stateModule="processEditor/stageList"
        :filters="filters"
        @change="doFilter"
        :moreFilters="false"
        :showErase="false"
        :showPagination="false"
    >
        <slot></slot>
    </st-filters-pagination>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { FilterSchema } from '@/shared/form/filter-schema';
    const filterSchema = new FilterSchema([]);

    export default {
        name: 'ProcessStepsFilter',
        props: {
            processId: String,
        },
        computed: {
            ...mapGetters({
               total: 'processEditor/stageList/total',
               record: 'processEditor/processForm/record',
            }),
            filters() {
                return filterSchema.fields;
            },
        },
        methods: {
            ...mapActions({
                doFetch: 'processEditor/stageList/doFetch',
            }),
            refresh() {
                this.doFilter(this.params);
            },
            doFilter(params) {
                this.params = params;
                this.doFetch({payload:params, id: !!this.processId ? this.processId : this.record.id});
            },
        },
    };
</script>
