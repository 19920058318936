<template>
    <div>
        <st-modal
            id="st-modal-process-stage"
            hide-header-delimiter
            hide-footer-delimiter
            size="md"
            hideable
            ref="modal"
        >
            <template #header>
                <p class="font-size-h5 font-weight-bold mb-0 pb-0">{{ headerText }}</p>
            </template>
            <template #body>
                <process-stage-form
                    ref="manage-process-stage-form"
                    :selectedStep="selectedStep"
                    :createdProcess="createdProcess"
                    :editMode="editMode"
                    :processId="processId"
                    :steps="steps"
                    @cancelForm="hide"
                    @submit="submit"
                >
                </process-stage-form>
            </template>
            <template #footer>
                <div class="d-flex justify-content-between w-100">
                    <st-button
                        variant="link"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="hide"
                    > {{ $t('GENERAL.BUTTON.CANCEL') }}
                    </st-button>
                    <st-button
                        variant="primary"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="manageProcess"
                    >
                        <span>{{buttonText}}</span>
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
import ProcessStageForm from './ProcessStageForm.vue';
export default {
    name: 'ProcessStageModal',
    components: {
        ProcessStageForm
    },
    props: {
        selectedStep: {
            type: Object,
            required: true
        },
        createdProcess: {
            type: Object,
            default: () => ({ })
        },
        editMode: {
            type: Boolean,
            default: false
        },
        processId: String,
        steps: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        buttonText() {
            return this.editMode ? this.$t('GENERAL.BUTTON.UPDATE') : this.$t('GENERAL.BUTTON.ADD');
        },
        headerText() {
            return this.editMode ?
                this.$t('PROCESS_EDITOR.STEPS.EDIT_MODAL_HEADER') :
                this.$t('PROCESS_EDITOR.STEPS.ADD_MODAL_HEADER');
        }
    },
    methods: {
        show() {
            this.$refs['modal'].show();
        },
        hide() {
            this.$refs['modal'].hide();
        },
        manageProcess() {
            this.$refs['manage-process-stage-form'].manageProcess();
        },
        submit() {
            this.$emit('updateProcess');
        }
    }
};
</script>
