<template>
    <st-page
        :title="$t('PROCESS_EDITOR.STEPS.HEADER')"
    >
        <template #toolbar>
            <st-button
                v-can:create="'processEditorSteps'"
                variant="primary"
                :callback="saveSteps"
                :disabled="!enableUpdateList && !!processId"
            >
                {{$t('PROCESS_EDITOR.STEPS.SAVE_LIST_BTN')}}
            </st-button>
            <st-button
                v-can:create="'processEditorSteps'"
                variant="secondary"
                :callback="addStep"
            >
                <i class="fa fa-plus"></i>
                {{$t('PROCESS_EDITOR.STEPS.ADD_BTN')}}
            </st-button>
        </template>
        <process-steps-filter ref="listProcessStepsFilter" :processId="processId">
            <loader v-if="isLoading"/>
            <steps-table
                v-if="!isLoading"
                :steps="rows"
                @editStep="editStep"
                @updateProcess="updateStep"
                @listUpdated="listUpdated"
                ref="process-step-table"
            />
        </process-steps-filter>
        <template #footer>
            <div class="d-flex justify-content-end w-100 py-5">
                <st-button
                    v-can:publish="'processEditorSteps'"
                    variant="primary"
                    :callback="publishWorkflow"
                >
                    <span> {{$t('PROCESS_EDITOR.STEPS.PUBLISH_BTN')}}</span>
                </st-button>
            </div>
        </template>
        <manage-step
            ref="manage-step"
            @updateProcess="updateStep"
            :selected-step="selectedStep"
            :createdProcess="createdProcess"
            :edit-mode="editMode"
            :processId="processId"
            :steps="rows"
        />
    </st-page>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import ManageStep from '@/modules/process-editor/components/ProcessStageModal.vue';
import StepsTable from '@/modules/process-editor/components/ProcessStageTable.vue';
import ProcessStepsFilter from '@/modules/process-editor/components/ProcessStageFilter.vue';
export default {
    name: 'ProcessForm',
    components: {  ManageStep, StepsTable, ProcessStepsFilter },
    props: {
        processId: String,
    },
    data() {
        return {
            editMode: false,
            createdProcess: {},
            selectedStep: {},
            enableUpdateList: false
        };
    },
    computed: {
        ...mapGetters({
            record: 'processEditor/processForm/record',
            rows: 'processEditor/stageList/rows',
            loading: 'shared/loading',
        }),
        isLoading() {
            return this.loading['processEditor/getSteps'] || this.loading['processStage/publishWorkflow'];
        },
    },
    created() {
        if (!this.processId) this.createdProcess = this.record;
    },
    methods: {
        ...mapActions({
            updateList: 'processEditor/stageList/doUpdate',
            doPublishWorkflow: 'processEditor/stageList/publishWorkflow',
            doFetch: 'processEditor/stageList/doFetch',
        }),
        showModal() {
            this.$refs['manage-step'].show();
        },
        addStep() {
            this.editMode = false;
            this.showModal();
        },
        updateStep() {
            this.$refs.listProcessStepsFilter.refresh();
        },
        editStep(data) {
            this.editMode = true;
            this.selectedStep = data;
            this.showModal();
        },
        async saveSteps() {
            const steps = this.$refs['process-step-table'].getSteps();
            const lastStepIndex = 999999;
            const payload = steps.map((step, index) => {
                delete step.key;

                return {
                    ...step,
                    order: step.order === lastStepIndex ? lastStepIndex : index
                }
            });

            await this.updateList(payload);
            
            this.enableUpdateList = false;
            await this.doFetch({payload: {}, id: this.$route.params.id});
        },
        listUpdated() {
            this.enableUpdateList = true;
        },
        publishWorkflow() {
            const processId = this.processId || this.createdProcess.id;
            const steps = this.$refs['process-step-table'].getSteps();

            if(steps[0].action_name !== 'Start') {
                this.$alert({
                    type: 'warning',
                        text: this.$t('PROCESS_EDITOR.STEPS.PUBLISH_MSG'),
                        confirmButtonText: this.$t('GENERAL.CLOSE'),
                        hasConfirmation: false,
                    });
            } else {
                this.$alert({
                    type: 'warning',
                    text: this.$t('PROCESS_EDITOR.STEPS.PUBLISH_CONFIRM_MSG'),
                    confirmButtonText: this.$t('GENERAL.YES'),
                    cancelButtonText: this.$t('GENERAL.NO'),
                    hasConfirmation: true,

                    confirmCallback: () => {
                        this.doPublishWorkflow(processId)
                        .finally(() => {
                            this.$refs.listProcessStepsFilter.refresh();
                        })
                    },
                });
            }
        }
    },
}
</script>
