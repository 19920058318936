import RelationToOneField from '@/shared/fields/relation-to-one-field';
import i18n from '@/shared/plugins/vue-i18n';

export class ApplicationStatusField {
    static relationToOne(name, label, options, filterParams) {
        return new RelationToOneField(
            name,
            label,
            (params) => {
                return new Promise((resolve) => {
                    if (!params) {
                        resolve([]);
                    }
                    resolve(params.outputVariables?.find( param => param.name === name)?.values || []);
                });                    
            },
            (status) => {
                if (!status) {
                    return null;
                }
                return {
                    value: status,
                    text: i18n.t(`APPLICATION.ENUMS.STATUS.${status.toUpperCase()}`),
                };
            },
            options,
            filterParams,
        );
    }
}
