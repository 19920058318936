import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import IdField from '@/shared/fields/id-field';
import StringField from '@/shared/fields/string-field';
import EnumField from '@/shared/fields/enum-field';

const label = (name) => i18n.t(`PROCESS_EDITOR.GENERAL.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`PROCESS_EDITOR.GENERAL.PLACEHOLDERS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`PROCESS_EDITOR.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);

const STATUSES = [
    {
        value: 'draft',
        name: 'draft',
        text: enumLabel('status', 'draft'),
        type: 'dark',
    },
    {
        value: 'pending',
        name: 'pending',
        text: enumLabel('status', 'pending'),
        type: 'warning',
    },
    {
        value: 'error',
        name: 'error',
        text: enumLabel('status', 'error'),
        type: 'danger',
    },
    {
        value: 'published',
        name: 'published',
        text: enumLabel('status', 'published'),
        type: 'success',
    },
];

const fields = {
    id: new IdField('id', 'Id'),
    display_name: new StringField(
        'display_name',
        label('name'),
        { required: true},
        { placeholder:  placeholder('name')}
    ),
    description: new StringField(
        'description',
        label('description'),
        { required: false,},
        { placeholder: placeholder('description')},
    ),
    status: new EnumField(
        'status',
        label('status'),
        STATUSES,
        {},
        { placeholder: placeholder('status') }
    ),
};

export class ProcessModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
