import RelationToOneField from '@/shared/fields/relation-to-one-field';
import ApiService from '@/shared/services/api.service';

export class FunctionTypeField {
    static relationToOne(name, label, rules, filterParams, children, options) {
        return new RelationToOneField(
            name,
            label,
            () => {
                return new Promise((resolve) => {
                    ApiService.query('/functions/process-management?limit=100').then(({data}) => {
                        resolve(data);
                    });
                })
            },
            (record) => {
                if (!record) {
                    return null;
                }

                return {
                    value: record.name,
                    text: record.name,
                };
            },
            rules,
            filterParams,
            children,
            options,
        );
    }
}
