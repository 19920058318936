import RelationToOneField from '@/shared/fields/relation-to-one-field';
import { ProcessService} from '@/modules/process-editor/process-editor-service';

export class ActionsField {
    static relationToOne(name, label, options, filterParams, children) {
        return new RelationToOneField(
            name,
            label,
            () => {
                return new Promise((resolve) => {
                    ProcessService.fetchActions()
                    .then((data) => {
                        resolve(data.data);
                    })
                })
            },
            (action) => {
                if (!action) {
                    return null;
                }
                return {
                    value: action.key,
                    text: action.name,
                };
            },
            options,
            filterParams,
            children
        );
    }
}
