<template>
    <div>
        <Loader v-if="loading['processStage/getActions']"/>
        <form class="form"
            novalidate="novalidate"
            id="st_general_process_form"
            ref="st_general_process_form"
        >
            <b-card class="st-section mt-6">
                <div class="st-section-header">
                   {{ $t('PROCESS_EDITOR.GENERAL.HEADER') }}
                </div>
                <div class="st-section-body row">
                    <div class="col-12 col-md-4">
                        <div class="form-group">
                            <label>
                                {{ fields.display_name.label }} *
                            </label>
                            <st-input-text
                                v-model="model[fields.display_name.name]"
                                :ref="fields.display_name.name"
                                :name="fields.display_name.name"
                                :disabled="disableName"
                            />
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group st-form-group-textarea">
                            <label>
                                {{ fields.description.label }}
                            </label>
                            <b-form-textarea
                                v-model="model[fields.description.name]"
                                class="st-form-textarea"
                                rows="10"
                                no-resize
                                ref="description"
                                name="description"
                            >
                            </b-form-textarea>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-end flex-column w-100 mt-10">
                    <st-button
                        variant="primary"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="manageProcess"
                    >
                        <span> {{$t('PROCESS_EDITOR.GENERAL.SAVE_BTN')}}</span>
                    </st-button>
                </div>
            </b-card>
        </form>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { createFormValidation } from '@/shared/utils/create-form-validation';
import { FormSchema } from '@/shared/form/form-schema';
import { ProcessModel } from '@/modules/process-editor/models/process-editor-model';

const { fields } = ProcessModel;

const formSchema = new FormSchema([
        fields.id,
        fields.display_name,
        fields.description,
    ]);

export default {
    name: 'ProcessGeneral',
    props: {
        processId: String,
    },
    data: () => ({
        fields,
        rules: formSchema.rules(),
        model: {},

    }),
    computed: {
        ...mapGetters({
            record: 'processEditor/processForm/record',
            currentUser: 'auth/currentUser',
            loading: 'shared/loading',
            actions: 'processEditor/stageForm/actions',
        }),
        disableName() {
            return !!this.$route.params.id;
        }
    },
    mounted() {
        this.fv = createFormValidation('st_general_process_form', this.rules);
    },
    created() {
        this.model = formSchema.initialValues(this.record);
        this.actionsList();
    },
    methods: {
        ...mapActions({
            addProcess: 'processEditor/processForm/create',
            addStep: 'processEditor/stageForm/create',
            updateProcess: 'processEditor/processForm/update',
            actionsList: 'processEditor/stageForm/actions',
        }),
        manageProcess() {
            this.fv.validate()
            .then((status) => {
                if (status === 'Valid') {
                    if (this.processId) {
                        this.doUpdateProcess();
                    } else {
                        this.doAddProcess();
                    }
                } else return false
            });
        },
        async doUpdateProcess() {
            const { id, ...payload } = this.model;
            await this.updateProcess({ id: this.model.id, payload});
            this.$emit('updateProcess', this.model);
        },
        async doAddProcess() {
            await this.addProcess({...this.model, tenant_id: this.currentUser.id, tenant_name: this.currentUser.last_name});
            const startStep = this.actions.find((action) => action.type === 'startEvent');
            const endStep = this.actions.find((action) => action.type === 'endEvent');

            const payloadStartStep = {
                action_id: startStep.id,
                action_name: startStep.name,
                description: startStep.description,
                key: startStep.key,
                name: this.$t('PROCESS_EDITOR.STEPS.ACTIONS.START'),
                workflow_id: this.record.id ,
                order: 0,
            }
            const payloadEndStep = {
                action_id: endStep.id,
                action_name: endStep.name,
                description: endStep.description,
                key: endStep.key,
                name: this.$t('PROCESS_EDITOR.STEPS.ACTIONS.END'),
                workflow_id: this.record.id ,
                order: 999999,
            }
            // Add start and end step after process is created
            await this.addStep(payloadStartStep);
            await this.addStep(payloadEndStep);
            this.$emit('nextStep');            
        }
    }
}
</script>